import React, { Component } from 'react';
import PropTypes, { array, arrayOf, bool, func, number, string } from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  getUserTxRole,
  TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_CONFIRM_DROP_OFF,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_CONFIRM_PAYMENT_INSTANT_UNVERIFIED,
  TRANSITION_CONFIRM_PAYMENT_UNVERIFIED,
  TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_ENQUIRE,
  TRANSITION_REQUEST_DROP_OFF_DLGO,
  TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE,
  TRANSITION_CONFIRM_PICK_UP_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
  TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_TRIP_PICK_UP_CONFIRMED_BY_ADMIN_AFTER_ACCEPTED_REFUNDABLE,
  TRANSITION_TRIP_PICKUP_CONFIRMED_BY_ADMIN_AFTER_ACCEPTED_NON_REFUNDABLE,
  txAfterCancelUpdateTrip,
  txHasBeenDelivered,
  txHasFirstReviewedByCustomer,
  txHasFirstReviewedByProvider,
  txInVerificationProcess,
  txIsAccepted,
  txIsAcceptedUpdateBookingBeforeDropOff,
  txIsAcceptedUpdateBookingBeforePickUp,
  txIsAutoAcceptedUpdateBookingBeforeDropOff,
  txIsAutoAcceptedUpdateBookingBeforePickUp,
  txIsAwaitUpdateBookingBeforeDropOff,
  txIsAwaitUpdateBookingBeforePickUp,
  txIsBeingDropOff,
  txIsBeingPickedUp,
  txIsCanceled,
  txIsCancelledUpdateBookingBeforeDropOff,
  txIsCancelledUpdateBookingBeforePickUp,
  txIsCompleted,
  txIsDeclined,
  txIsEnquired,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsPickedUp,
  txIsRequested,
  txIsShu,
  txIsUnverifiedInstantRequested,
  txIsUnverifiedRequested,
  txIsUnverifiedSubmitted,
  txIsUnverifiedSubmittedInstant,
  txIsWithdrawn,
  txIsWithdrawnByAdmin,
  txIWaitingRequestDropoff,
  txIWaitingRequestPickup,
  txIsReturnedBackBeforePickUp,
  TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE,
} from '../../util/transaction';
import {
  txIsBeingPickedUpLTF,
  txIsDeclinedLTF,
  txIsPickedUpLTF,
  txIsRequestedLTF,
  txIWaitingRequestPickupLTF,
  txLastTransition,
} from '../../util/transactionLongTermFirst';
import {
  txHasFirstReviewedByCustomerLTL,
  txHasFirstReviewedByProviderLTL,
  txIsBeingDropOffLTL,
  txIsCompletedLTL,
  txIWaitingRequestDropoffLTL,
} from '../../util/transactionLongTermLast';
import { propTypes } from '../../util/types';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { convertMoneyToNumber } from '../../util/currency';
import { isMobileSafari } from '../../util/userAgent';
import {
  BookingInfoSection,
  ModalCancel,
  ModalCancelPolicy,
  ModalHelp,
  PartnerInfo,
  ReviewModal,
  UserDisplayName,
  TripExtensionModal,
  Modal,
} from '../../components';
import { SendMessageForm } from '../../forms';
import config from '../../config';
import {
  CANCEL_BOOKING_BUTTON_ID,
  EVENT_BOOK_REQUEST_WITHDRAWN_GUEST,
  EVENT_TRIP_DISPUTED_GUEST,
  EVENT_TRIP_DISPUTED_HOST,
  EVENT_TRIP_DROPOFF_REQUEST_CONFIRM,
  EVENT_TRIP_DROPOFF_REQUEST_CONFIRM_GUEST,
  EVENT_TRIP_DROPOFF_REQUEST_RECEIVED,
  EVENT_TRIP_DROPOFF_REQUEST_SENT,
  EVENT_TRIP_GUEST_CANCEL_GUEST,
  EVENT_TRIP_GUEST_REVIEW_GUEST,
  EVENT_TRIP_GUEST_REVIEW_HOST,
  EVENT_TRIP_HOST_CANCEL_HOST,
  EVENT_TRIP_HOST_REVIEW_GUEST,
  EVENT_TRIP_HOST_REVIEW_HOST,
  EVENT_TRIP_MESSAGE_SENT_GUEST,
  EVENT_TRIP_MESSAGE_SENT_HOST,
  EVENT_TRIP_PICKUP_REQUEST_CONFIRM,
  EVENT_TRIP_PICKUP_REQUEST_CONFIRM_GUEST,
  EVENT_TRIP_PICKUP_REQUEST_RECEIVED,
  EVENT_TRIP_PICKUP_REQUEST_SENT,
  SERVER_EVENT_BOOK_REQUEST_WITHDRAWN_HOST,
  SERVER_EVENT_TRIP_GUEST_CANCEL_HOST,
  SERVER_EVENT_TRIP_HOST_CANCEL_GUEST,
  TRIP_PRIMARY_BUTTON_TRANSIT_NAME,
  TRIP_PUBLISH_REVIEW_BUTTON_ID,
  TRIP_SEND_MESSAGE_BUTTON_ID,
  TRIP_SUBMIT_DISPUTE_BUTTON_ID,
} from '../../util/gtm/gtmConstants';
import {
  initiateEventFromTransaction,
  pushGTMTripEvent,
} from '../../util/gtm/gtmHelpers';
import AddressLinkMaybe from './AddressLinkMaybe';
import FeedSection from './FeedSection';
import PanelHeading, {
  HEADING_ACCEPTED,
  HEADING_CANCELED,
  HEADING_DECLINED,
  HEADING_DELIVERED,
  HEADING_ENQUIRED,
  HEADING_ONGOING,
  HEADING_PAYMENT_EXPIRED,
  HEADING_PAYMENT_PENDING,
  HEADING_REQUESTED,
  HEADING_WITHDRAWN,
  HEADING_WITHDRAWN_BY_ADMIN,
} from './PanelHeading';
import {
  HEADING_ACCEPTED_UPDATE_BOOKING,
  HEADING_DECLINED_UPDATE_BOOKING,
  HEADING_REQUEST_UPDATE_BOOKING,
} from '../TransactionPanel/PanelHeading';
import css from './TripPanel.css';
import { dropoffTransitions, LONG_TERM_STATUS_FINISH } from './DropOffButtonsMaybe';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import routeConfiguration from '../../routeConfiguration';
import { createSlug, stringify } from '../../util/urlHelpers';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import ListingMap from './ListingMap';
import CustomerMap from './CustomerMap';
import BookingStatus from './BookingStatus';
import InfoSection from './InfoSection';
import ImportantLinksSection from './ImportantLinksSection';
import LockBoxSection from './LockBoxSection';

import UpdateBookingButtonsMaybe from './UpdateBookingButtonsMaybe';
import ButtonsSection from './ButtonsSection';
import { pickupTransitions } from './PickupButtonsMaybe';
import {
  getNextCancelTransition,
  getNextInteractableTransition,
  getTransitions,
  getAdminTransitions,
  getTransitionsOld
} from './transitionHelper';

import PickUpSection from './PickUpSection';
import DropOffSection from './DropOffSection';
import ModalReasonDispute from './ModalReasonDispute';
import { LockUnlockButtonMaybe } from './LockUnlockButtonsMaybe';
import { richText } from '../../util/richText';
import {checkTripCondition, getGpsLocationCoordinates} from '../../util/shuStatus';
import { AccountSettingDriverVerificationPage } from '../../containers';
import StartTripPanel from './StartTripPanel';
import PickUpByAdmin from '../PickUpByAdmin/PickUpByAdmin'
import { getAddressDetailsByLatLong } from '../../util/googleMaps';
import Loader from '../../containers/StripePayoutPage/Loader';
import { failedDistanceTransitions } from '../../containers/DistanceCharging/distanceChargingHelpers';
import distance_modal from './../../assets/tripPage/distance_modal.svg'
import protection_modal from './../../assets/tripPage/protection_modal.svg'
import toll_modal from './../../assets/tripPage/toll_modal.svg'
import AlertBox from '../AlertBox/AlertBox';

const SHU_STATUS_DISPLAY_STATUS = {
  INIT: 0,
  SUCCESS: 1,
  ERROR: 2,
  LOADING: 3,
};

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;
  let otherUser = null;
  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUser = currentProvider;
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUser = currentCustomer;
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
    otherUser,
  };
};

export class TripPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewPickUpDropOffSection: true,
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      isTripExtensionModalOpen: false,
      isCancelPolicyOpen: false,
      reviewSubmitted: false,
      isStatic: true,
      showCancelModal: false,
      showCancelHelpModal: false,
      showDisputeModal: false,
      showImgModal: false,
      showImgModalUrl: null,
      reasonDispute: '',
      currentPhotos: [],
      tripPhotos: [],
      exteriorPhotos: [],
      selfieWithId: [],
      interiorPhotos: [],
      odometerPhotos: [],
      fuelReceiptPhotos: [],
      fuelGaugePhotos: [],
      pickupProviderPhoto: [],
      reasonDisputePhotoUrls: [],
      isOpenRentalAgreement: false,
      userCloseModal: false,
      numberOdometer: null,
      isReadMore: null,
      showPickUpSection: false,
      showDropOffSection: false,
      activePhotoSection: [],
      editWithAdditionalTime: false,
      isEditBookingActive: false,
      isDonateTransactionActive: false,
      shuStatusLocation: {
        status: SHU_STATUS_DISPLAY_STATUS.INIT,
        location: {},
        address: '',
      },
      cleanlinessValue: {},
      activePickUpBooking: false,
      activeDropOffBooking: false,
      showViewDetails: false,
      tripInfo: {
        showAfter15Minute: false,
        showListingLocation: false,
        showLiveLocation: false,
      },
      exactGeoLocation: null,
      exactAddress: "",
      adminCheckGuest: false,
      adminCheckGuestLocation: false,
      lockBoxCode: '',
      expireTime: '',
      exactLocationLoading: false,
      isKeyInformationDetialsModalOpen: false,
      keyDetailsModalContents: {},
      adminAction: false,
      dropOffSentAtByAdmin: null,
      customDropOffReadingByAdmin: null,
    };

    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onOpenTripExtensionModal = this.onOpenTripExtensionModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
    this.shouldShowPickUpSection = this.shouldShowPickUpSection.bind(this);
    this.shouldShowDropOffSection = this.shouldShowDropOffSection.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.updateLockboxData = this.updateLockboxData.bind(this);
    this.setAdminAction = this.setAdminAction.bind(this);
    this.setDropOffSentAt = this.setDropOffSentAt.bind(this);
  }

  handleCheckboxChange(name, value) {
    this.setState({ [name]: value });
  }

  updateLockboxData(code, expiry) {
    this.setState({
      lockBoxCode: code,
      expireTime: expiry,
    });
  }


  async componentDidMount(){

    const { transaction, listingForMap } = this.props;
    const { geolocation } = listingForMap.attributes;
    const currentTransaction = ensureTransaction(transaction);
    this.setState({exactLocationLoading: true})
    await this.checkTripConditionFromBackend(this.props.transaction.id.uuid);
    const exactLocation = await this.getAccurateGeoLocation(currentTransaction, listingForMap, geolocation)
    this.setState({exactLocationLoading: false})
    const currentListing = ensureListing(currentTransaction.listing);
    const timeZone = getDefaultTimeZoneOnBrowser(
      currentListing.attributes.publicData && currentListing.attributes.publicData.listingTimezone
    );
    const { displayStart } = get(currentTransaction, 'booking.attributes', {});
    const currentTime = moment().tz(timeZone); // Current time
    const pickupTime = moment(displayStart).tz(timeZone); // Display time is the pickup time

    // Calculate Show Live Location Time based on booking type
    const showLiveLocationTime = pickupTime.clone().subtract(1, 'hour'); // Daily: Pickup time - 1 hour


    // Determine if Show Exact Shu Location should be enabled
    const showExactShuLocation = currentTime.isSameOrAfter(showLiveLocationTime);
    const parkingType = get(currentListing, "attributes.publicData.parkingType", "")
    const hasPrimaryGps = get(currentListing, "attributes.publicData.deviceManagement.primaryGps.isVerified", false)
    const isDrivLahGo = get(currentListing, "attributes.metadata.isDrivelahGo", false)
    const approxLocation = this.getApproximateGeoLocation(listingForMap, geolocation);

    const isDropOff =
      txIsBeingDropOff(currentTransaction) ||
      txIWaitingRequestDropoff(currentTransaction) ||
      txIWaitingRequestDropoffLTL(currentTransaction) ||
      txIsBeingDropOffLTL(currentTransaction);

      if (showExactShuLocation) {
        if(!isDropOff){
          if (hasPrimaryGps || isDrivLahGo) {
            if(parkingType === "street") {
              this.setState({
                exactGeoLocation: exactLocation.geolocation,
                exactAddress: exactLocation.address
              })
            } else {
              this.setState({
                exactGeoLocation: approxLocation.geolocation,
                exactAddress: approxLocation.address
              })
            }
          } else {
            this.setState({
              exactGeoLocation: approxLocation.geolocation,
              exactAddress: approxLocation.address
            })
          }
        }else{
          this.setState({
            exactGeoLocation: approxLocation.geolocation,
            exactAddress: approxLocation.address
          })
        }

      }else{
        this.setState({
          exactAddress: approxLocation.address,
          exactGeoLocation: approxLocation.geolocation
        })
      }

    this.isMobSaf = isMobileSafari();
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.userCloseModal) {
      if (!this.state.isReviewModalOpen) {
        const { transaction, transactionRole } = nextProps;
        const isCustomer = transactionRole === 'customer';
        const isProvider = transactionRole === 'provider';
        if (transaction) {
          const isCompleted = txIsCompleted(transaction) || txIsCompletedLTL(transaction);
          const isReviewedByCustomer =
            txHasFirstReviewedByCustomer(transaction) ||
            txHasFirstReviewedByCustomerLTL(transaction);
          const isReviewedByProvider =
            txHasFirstReviewedByProvider(transaction) ||
            txHasFirstReviewedByProviderLTL(transaction);

          if (isCompleted) {
            if (isCustomer && !isReviewedByCustomer) {
              this.onOpenReviewModal();
              this.setState({ userCloseModal: true });
            }
            if (isProvider && !isReviewedByProvider) {
              this.onOpenReviewModal();
              this.setState({ userCloseModal: true });
            }
          } else {
            if (isReviewedByCustomer && isProvider && !isReviewedByProvider) {
              this.onOpenReviewModal();
              this.setState({ userCloseModal: true });
            }
            if (isReviewedByProvider && isCustomer && !isReviewedByCustomer) {
              this.onOpenReviewModal();
              this.setState({ userCloseModal: true });
            }
          }
        }
      }
    }
  }

  handleOpenStatusModal = idBtn => {
    document.getElementById(idBtn).click();
  };

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  onOpenTripExtensionModal() {
    this.setState({ isTripExtensionModalOpen: true });
  }

  onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole } = this.props;
    let currentTransaction = ensureTransaction(transaction);
    const isFinishLongTerm =
      get(transaction, 'attributes.metadata.longTermStatus') === LONG_TERM_STATUS_FINISH;
    if (isFinishLongTerm) {
      currentTransaction = transaction && transaction.currentChildTransaction;
    }
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    const events =
      transactionRole === 'provider'
        ? [EVENT_TRIP_HOST_REVIEW_GUEST, EVENT_TRIP_HOST_REVIEW_HOST]
        : [EVENT_TRIP_GUEST_REVIEW_HOST, EVENT_TRIP_GUEST_REVIEW_GUEST];

    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      .then(r => {
        pushGTMTripEvent({
          props: this.props,
          event: events[0],
          transaction,
          buttonId: TRIP_PUBLISH_REVIEW_BUTTON_ID,
          existTransaction: true,
          userDiffActionTaker: true,
        });
        pushGTMTripEvent({
          props: this.props,
          event: events[1],
          transaction,
          buttonId: TRIP_PUBLISH_REVIEW_BUTTON_ID,
          existTransaction: true,
        });
        this.setState({ isReviewModalOpen: false, reviewSubmitted: true });
      })
      .catch(e => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage, transactionRole } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);
    const event =
      transactionRole === 'provider' ? EVENT_TRIP_MESSAGE_SENT_HOST : EVENT_TRIP_MESSAGE_SENT_GUEST;

    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        pushGTMTripEvent({
          props: this.props,
          event: event,
          transaction,
          buttonId: TRIP_SEND_MESSAGE_BUTTON_ID,
          existTransaction: true,
        });
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  handleChooseDisputePhoto(fileUrl) {
    const isExisted = this.state.reasonDisputePhotoUrls.find(url => url === fileUrl);
    if (isExisted) {
      this.setState({
        reasonDisputePhotoUrls: this.state.reasonDisputePhotoUrls.filter(url => url !== fileUrl),
      });
    } else {
      this.setState({
        reasonDisputePhotoUrls: [...this.state.reasonDisputePhotoUrls, fileUrl],
      });
    }
  }

  onPushDisputeEventGTM = () => {
    const { transaction, transactionRole } = this.props;
    pushGTMTripEvent({
      props: this.props,
      event: EVENT_TRIP_DISPUTED_HOST,
      transaction,
      buttonId: TRIP_SUBMIT_DISPUTE_BUTTON_ID,
      existTransaction: true,
    });
    pushGTMTripEvent({
      props: this.props,
      event: EVENT_TRIP_DISPUTED_GUEST,
      transaction,
      buttonId: TRIP_SUBMIT_DISPUTE_BUTTON_ID,
      existTransaction: true,
      userDiffActionTaker: true,
    });
  };

  onPushPickUpAndDropOffEventGTM = (transaction, transitionName) => {
    let event = [];
    if (transitionName && transitionName.includes('request-pick-up')) {
      event = [EVENT_TRIP_PICKUP_REQUEST_SENT, EVENT_TRIP_PICKUP_REQUEST_RECEIVED];
    } else if (transitionName && transitionName.includes('confirm-pick-up')) {
      event = [EVENT_TRIP_PICKUP_REQUEST_CONFIRM, EVENT_TRIP_PICKUP_REQUEST_CONFIRM_GUEST];
    } else if (transitionName && transitionName.includes('request-drop-off')) {
      event = [EVENT_TRIP_DROPOFF_REQUEST_SENT, EVENT_TRIP_DROPOFF_REQUEST_RECEIVED];
    } else if (transitionName && transitionName.includes('confirm-drop-off')) {
      event = [EVENT_TRIP_DROPOFF_REQUEST_CONFIRM, EVENT_TRIP_DROPOFF_REQUEST_CONFIRM_GUEST];
    }
    pushGTMTripEvent({
      props: this.props,
      event: event[0],
      transaction,
      buttonId: TRIP_PRIMARY_BUTTON_TRANSIT_NAME,
      existTransaction: true,
      isMultipleButton: true,
    });
    pushGTMTripEvent({
      props: this.props,
      event: event[1],
      transaction,
      buttonId: TRIP_PRIMARY_BUTTON_TRANSIT_NAME,
      existTransaction: true,
      isMultipleButton: true,
      userDiffActionTaker: true,
    });
  };

  onCancelTripGTM = isAccepted => {
    const { transaction, transactionRole } = this.props;
    let events;
    if (isAccepted) {
      events =
        transactionRole === 'provider'
          ? [SERVER_EVENT_TRIP_HOST_CANCEL_GUEST, EVENT_TRIP_HOST_CANCEL_HOST]
          : [SERVER_EVENT_TRIP_GUEST_CANCEL_HOST, EVENT_TRIP_GUEST_CANCEL_GUEST];
      pushGTMTripEvent({
        props: this.props,
        event: events[0],
        transaction,
        buttonId: CANCEL_BOOKING_BUTTON_ID,
        buttonText: 'cancel',
        existTransaction: true,
        userDiffActionTaker: true,
        isServerSideEvent: true,
      });
      pushGTMTripEvent({
        props: this.props,
        event: events[1],
        transaction,
        buttonId: CANCEL_BOOKING_BUTTON_ID,
        buttonText: 'cancel',
        existTransaction: true,
      });
    } else {
      events = [SERVER_EVENT_BOOK_REQUEST_WITHDRAWN_HOST, EVENT_BOOK_REQUEST_WITHDRAWN_GUEST];
      initiateEventFromTransaction({
        props: this.props,
        transaction,
        buttonId: CANCEL_BOOKING_BUTTON_ID,
        buttonText: 'cancel',
        event: events[0],
        existTransaction: true,
        userDiffActionTaker: true,
        isServerSideEvent: true,
      });
      initiateEventFromTransaction({
        props: this.props,
        transaction,
        buttonId: CANCEL_BOOKING_BUTTON_ID,
        buttonText: 'cancel',
        event: events[1],
        existTransaction: true,
      });
    }
  };

  setAdminAction(value) {
    this.setState({adminAction: value});
  }

  setDropOffSentAt(value) {
    this.setState({dropOffSentAtByAdmin: value});
  }

  shouldShowPickUpSection(value) {
    if (value.target) {
      this.setState({
        showPickUpSection: !this.state.showPickUpSection,
      });
    } else {
      this.setState({
        showPickUpSection: value,
      });
    }
  }

  setShowViewDetails =()=> {
    this.setState({
      showViewDetails: !this.state.showViewDetails,
    })
  }

  shouldShowDropOffSection(value) {
    if (value.target) {
      this.setState({
        showDropOffSection: !this.state.showDropOffSection,
      });
    } else {
      this.setState({
        showDropOffSection: value,
      });
    }
  }


  setActivePhotoSection = photos => {
    const { exteriorPhotos } = this.state;
    const exteriorPhotosBool = photos[0].type === 'exteriorPhotos';
    const interiorPhotosBool = photos[0].type === 'interiorPhotos';
    const odometerPhotosBool = photos[0].type === 'odometerPhotos';
    const fuelGaugePhotosBool = photos[0].type === 'fuelGaugePhotos';
    const fuelReceiptPhotosBool = photos[0].type === 'fuelReceiptPhotos';
    const selfieWithIdBool = photos[0].type === 'selfieWithId';
    const pickupProviderPhotosBool = photos[0].type === 'pickupProviderPhoto';
    const index = photos[0].id;
    const currentActivePhotoSection = this.state.activePhotoSection;
    const totatalExteriorPhotos = exteriorPhotosBool && [...exteriorPhotos, ...photos];
    if (exteriorPhotosBool && totatalExteriorPhotos.length >= 4) {
      currentActivePhotoSection[index] = true;
      this.setState({
        activePhotoSection: [...currentActivePhotoSection],
      });
    }
    if (
      (interiorPhotosBool ||
        odometerPhotosBool ||
        fuelGaugePhotosBool ||
        fuelReceiptPhotosBool ||
        selfieWithIdBool ||
        pickupProviderPhotosBool) &&
      photos.length >= 1
    ) {
      currentActivePhotoSection[index] = true;
      this.setState({
        activePhotoSection: [...currentActivePhotoSection],
      });
    }
    this.setState({
      currentPhotos: [...this.state.currentPhotos, ...photos],
    });
  };

  handleRemovePhoto = (fileUrl) => {
    this.setState(prevState => ({
      currentPhotos: prevState.currentPhotos.filter(photo => photo.fileUrl !== fileUrl),
    }));
  };

  checkLatLngParams = (lat, lng) => {
    return lat && !isNaN(lat) && lng && !isNaN(lng);
  };

  getLocationData = listing => {
    const getLocation = () => {
      return (
        (listing &&
          listing.attributes &&
          listing.attributes.publicData &&
          listing.attributes.publicData.location) ||
        {}
      );
    };

    const location = getLocation();
    const pc = location.postalCode || '';
    const address = location.address || '';
    return { pc, defaultAddress: address };
  };

  getAccurateGeoLocation = async (transaction, listingForMap, geolocation) => {
    const { defaultAddress } = this.getLocationData(listingForMap);
    const currentTransaction = ensureTransaction(transaction);
    const currentListing = ensureListing(listingForMap);
    const hasPrimaryGps = get(currentListing, "attributes.publicData.deviceManagement.primaryGps.isVerified", false)
    const hasOtoplug = get(currentListing, "attributes.publicData.deviceManagement.dlGo.isVerified", false)

    let params = {
      listingId: currentListing.id.uuid,
      transactionId: currentTransaction.id.uuid,
    }
    console.warn("transaction", transaction)

    if (hasPrimaryGps) {
      params.hasPrimaryGps = true;
      const data = await getGpsLocationCoordinates(params)
      if (data) {
        const address = await getAddressDetailsByLatLong(data.latitude, data.longitude);
        if (this.checkLatLngParams(data.latitude, data.longitude)) {
          return {
            geolocation: {
              ...geolocation,
              lat: Number(data.latitude),
              lng: Number(data.longitude),
            },
            address: address || '',
          };
        }
      }
    }
    else if(hasOtoplug){
      params.hasOtoplug = true;
      const data = await getGpsLocationCoordinates(params)
      if (data) {
        const address = await getAddressDetailsByLatLong(data.latitude, data.longitude);
        if (this.checkLatLngParams(data.latitude, data.longitude)) {
          return {
            geolocation: {
              ...geolocation,
              lat: Number(data.latitude),
              lng: Number(data.longitude),
            },
            address: address || '',
          };
        }
      }
    }

    else{
      const data = await getGpsLocationCoordinates(params)
    if (data) {
      const address = await getAddressDetailsByLatLong(data.last_known_gps_latitude, data.last_known_gps_longitude);
      if (this.checkLatLngParams(data.last_known_gps_latitude, data.last_known_gps_longitude)) {
        return {
          geolocation: {
            ...geolocation,
            lat: Number(data.last_known_gps_latitude),
            lng: Number(data.last_known_gps_longitude),
          },
          address: address || '',
        };
      }
    }
    }

    const data = await getGpsLocationCoordinates(params)
    if (data) {
      const address = await getAddressDetailsByLatLong(data.latitude, data.longitude);
      if (this.checkLatLngParams(data.latitude, data.longitude)) {
        return {
          geolocation: {
            ...geolocation,
            lat: Number(data.latitude),
            lng: Number(data.longitude),
          },
          address: address || '',
        };
      }
    }

    return { geolocation, address: defaultAddress };
  };

  getApproximateGeoLocation = (listingForMap, geolocation) => {
    const { defaultAddress } = this.getLocationData(listingForMap);
    return {
      geolocation,
      address: {
        address: defaultAddress
      },
    };
  };

  checkTripConditionFromBackend = async (tId) => {
    console.log("checkTripConditionFromBackend called", tId)
    const setStateForTripInfo= ({showAfter15Minute, showListingLocation, showLiveLocation}) => {
      this.setState({
        tripInfo: {
          showAfter15Minute,
          showListingLocation,
          showLiveLocation,
        },
      });
    };

    try {
      const response = await checkTripCondition(tId);
      console.log("response in response", response)
      if (response) {
        setStateForTripInfo({
          showAfter15Minute: response.showAfter15Minute,
          showListingLocation: response.showListingLocation,
          showLiveLocation: response.showLiveLocation
        });
      }
    } catch (error) {
      console.log("Error in checking Condition from backend" ,error)
    }
  }

  render() {
    const {
      cancelAllUpdateBookingTxsInProgress,
      bookingUpdateTxs,
      lastBookingUpdate,
      acceptUpdateBookingInProgress,
      cancelUpdateBookingInProgress,
      rootClassName,
      className,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onAcceptSale,
      onDeclineSale,
      acceptInProgress,
      declineInProgress,
      acceptSaleError,
      declineSaleError,
      acceptButtonName,
      declineButtonName,
      onTransit,
      transitInProgress,
      transitError,
      keyRulesConfig,
      onSendMessage,
      ownListing,
      nextTransitions,
      transactionWithoutRestore,
      acceptSaleGTM,
      declineSaleGTM,
      onHandlePaymentFuel,
      isMobileLayout,
      isTabletLayout,
      history,
      onUploadInteriorPhotoToMetadata,
      uploadInteriorPhotoProgress,
      uploadInteriorSuccess,
      onFetchCancelUpdateBooking,
      onFetchTimeSlots,
      monthlyTimeSlots,
      timeSlots,
      onEstimateBreakdown,
      estimateBreakdownInProgress,
      estimateError,
      estimatedTx,
      customerUpdateBookingInProgress,
      transactionUpdateBooking,
      listingForMap,
      onAcceptUpdateBooking,
      onCancelUpdateBooking,
      onCancelAllUpdateBookings,
      isLimitedUser,
      onCancelUpdateBookingByAdmin,
      onAcceptUpdateBookingByAdmin,
      isCancelUpdateBookingByAdminPending,
      isAcceptUpdateBookingByAdminPending,
      getDistanceFromPickUp,
      distanceFromPickUp,
      userLocation
    } = this.props;

    console.log(this.state.shuStatusLocation)
    const findDeviceByType = (existingDeviceData, deviceType) => {
      let devicesData = {};
      Object.keys(existingDeviceData).forEach(deviceKey => {
        const device = existingDeviceData[deviceKey];

        // If the deviceType matches, add it to devicesData
        if (device.deviceType === deviceType) {
          devicesData[deviceKey] = {
            deviceType: device.deviceType,
            byod: device.byod,
            serial: deviceType === 'dlGo' ? device.imei : device.serial,
            image: device.image,
            model: device.model,
            imei: deviceType === 'dlGo' ? device.imei : '', // Keep IMEI for dlGo
            isVerified: device.isVerified || false,
            code: device.code,
            dateValid: device.dateValid

          };
        }
      });
      return devicesData;
    };
    console.log("isLimitedUser.....", isLimitedUser)
    const currentTransaction = ensureTransaction(transaction);
    const transactionId = get(currentTransaction, 'id.uuid');
    const lastTransition = txLastTransition(transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';
    const timeZone = getDefaultTimeZoneOnBrowser(
      currentListing.attributes.publicData && currentListing.attributes.publicData.listingTimezone
    );

    const photoObjects = get(currentTransaction, 'attributes.protectedData.photoObjects', []);
    const flatPhotoObjects = photoObjects && photoObjects.flat();
    const pickUpOdometer = parseInt(get(flatPhotoObjects.find((photo) => get(photo, 'odometerPhotos', false) && get(photo, 'isPickUp', false)), "note", 0));
    const { adminCheckGuest, adminCheckGuestLocation } = this.state;
    const isPickUpByAdminButtonEnabled = adminCheckGuest && adminCheckGuestLocation;

    const fuelType = currentListing.attributes.publicData.fuelType;
    const customerLocation = currentTransaction.attributes.protectedData.customerLocation;
    // Lockbox code
    const lockbox = get(currentListing, 'attributes.publicData.lockbox', false);
    const existingDeviceData = get(currentListing, 'attributes.publicData.deviceManagement', {});
    const existingPlanData = findDeviceByType(existingDeviceData, 'lockbox');
    const isLockboxActive = lockbox && existingPlanData.lockbox && existingPlanData.lockbox.serial;

    console.log("CLOCCCCCCCCCCCCCCCCCCCCCCCCCCCC", lockbox, existingPlanData, isLockboxActive)

    let requirePickupLocation = currentUser?.attributes?.profile?.publicData?.requirePickupLocation
    let requireDropoffLocation = currentUser?.attributes?.profile?.publicData?.requireDropoffLocation
    console.log('requirePickupLocation', currentUser?.attributes?.profile?.publicData?.requirePickupLocation)
    console.log('requireDropoffLocation', currentUser?.attributes?.profile?.publicData?.requireDropoffLocation)

    const customerAddress = customerLocation && customerLocation.selectedPlace;
    const listingId = currentListing.id.uuid;
    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;
    const isLongTerm = get(transaction, 'attributes.protectedData.isLongTermRental');
    const isFinishLongTerm =
      get(transaction, 'attributes.metadata.longTermStatus') === LONG_TERM_STATUS_FINISH;
    const stateData = this.stateDataFn(isCustomer, transaction, isProvider, isCustomerBanned);
    const currentUserRole = getUserTxRole(currentUser.id, transaction);
    const isInstantBooking = currentListing.attributes.publicData.instantBooking;
    const isDrivelahGo =
      currentTransaction.attributes.protectedData.isDrivelahGo ||
      currentListing.attributes.metadata.isDrivelahGo;
    console.log('isDrivelahGo', isDrivelahGo)
    const shuLocationAvailble = currentTransaction.attributes.metadata && currentTransaction.attributes.metadata.pickupSHULocation
    const isHourlyBooking = get(currentTransaction, 'attributes.protectedData.hourlyBooking');
    const depositTx = get(currentTransaction, 'attributes.protectedData.depositTx');
    const chargedDepositTx = get(currentUser, 'attributes.profile.metadata.depositChildTx');
    const showDepositTxSection = !isProvider && depositTx !== undefined && chargedDepositTx !== undefined && depositTx === chargedDepositTx;

    const { displayStart } = get(currentTransaction, 'booking.attributes', {});
    const isTimeToShowTransitionPickup = moment().isSameOrAfter(
      moment(displayStart).add(isHourlyBooking ? -15 : -60, 'minutes')
    );
    console.log(transaction, "transaction")
    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });
    const dropoffSentAt = transaction.attributes.metadata.dropoffSentAt

    const currentChildTransaction = get(
      currentTransaction,
      'attributes.metadata.currentChildTransaction'
    );
    const nextCancelTransition = getNextCancelTransition(
      currentChildTransaction ? currentTransaction.currentChildTransaction : currentTransaction,
      isProvider
    );
    const haveNextCancelTransit = nextCancelTransition.length > 0;

    const transactionForCancel = !isLongTerm
      ? transactionWithoutRestore
      : currentChildTransaction
      ? currentTransaction.currentChildTransaction
      : currentTransaction;

    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
      otherUser,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const { publicData, geolocation } = listingForMap && listingForMap.attributes;
    const location = publicData && publicData.location ? publicData.location : {};
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;
    const currentTime = moment(); // Current time
    const pickupTime = moment(displayStart); // Display time is the pickup time

    // Calculate Show Live Location Time based on booking type
    const showLiveLocationTime = pickupTime.clone().subtract(1, 'hour'); // Daily: Pickup time - 1 hour

    // Determine if Show Exact Shu Location should be enabled
    const showExactShuLocation = currentTime.isSameOrAfter(showLiveLocationTime);
    const showFetchLiveLocationButton = pickupTime.diff(currentTime, 'minutes') <= 15 && !showExactShuLocation
    const isTripYetToStart = true;

    const listingRegistrationNumber = currentListing.attributes.publicData.license_plate_number;
    const showSendMessageForm =
      !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });

    const updateBookingButtons = (
      <UpdateBookingButtonsMaybe
        showButtons={stateData.showBookingButtons}
        transaction={currentTransaction}
        acceptInProgress={acceptUpdateBookingInProgress}
        declineInProgress={cancelUpdateBookingInProgress}
        onAcceptUpdateBooking={onAcceptUpdateBooking}
        onCancelUpdateBooking={onCancelUpdateBooking}
        bookingUpdateTxs={bookingUpdateTxs}
        intl={intl}
        history={history}
        isLimitedUser={isLimitedUser}
        onCancelUpdateBookingByAdmin={onCancelUpdateBookingByAdmin}
        onAcceptUpdateBookingByAdmin={onAcceptUpdateBookingByAdmin}
        isCancelUpdateBookingByAdminPending={isCancelUpdateBookingByAdminPending}
        isAcceptUpdateBookingByAdminPending={isAcceptUpdateBookingByAdminPending}
      />
    );

    const { title = '' } = currentListing.attributes;
    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: 16,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage id="TransactionPanel.bookingTitle" values={{ title: richTitle }} />
    );

    const listing = get(transaction, 'listing');
    const isDeliveryTransaction = get(currentTransaction, 'attributes.protectedData.isDelivery');
    const classes = classNames(rootClassName || css.root, className);
    const actionButtonClasses = classNames(css.actionButtons);
    const nextInteractableTransitions = getNextInteractableTransition(
      isFinishLongTerm ? currentTransaction.currentChildTransaction : currentTransaction,
      isProvider
    );
    const haveNextTransit = nextInteractableTransitions.length > 0;

    const validTripPhotos =
      currentTransaction.attributes.protectedData.photoObjects &&
      currentTransaction.attributes.protectedData.photoObjects.length > 0;
    const metaPhotoObject =
      currentTransaction &&
      currentTransaction.attributes &&
      currentTransaction.attributes.protectedData &&
      currentTransaction.attributes.protectedData.photoObjects;
    const interiorPhotoDlGo = metaPhotoObject
      ? metaPhotoObject.filter(i => i.filter(j => j['interiorPhotos']).length > 0)
      : [];
    const odometerPhotoDlGo = metaPhotoObject
      ? metaPhotoObject.filter(i => i.filter(j => j['odometerPhotos']).length > 0)
      : [];
    const fuelGaugePhotoDlGo = metaPhotoObject
      ? metaPhotoObject.filter(i => i.filter(j => j['fuelGaugePhotos']).length > 0)
      : [];
    const tripPhotosProtectedData = validTripPhotos
      ? currentTransaction.attributes.protectedData.photoObjects
      : [];
    const allTripPhotos = [...tripPhotosProtectedData];

    const isOlderVersion = !currentTransaction.attributes.protectedData.isDistanceCharge
    console.log('olderVersion', isOlderVersion)

    const transitionMaybe = isOlderVersion ?
      get(getTransitionsOld(transaction), `${currentUserRole}`, null) :
      get(getTransitions(transaction), `${currentUserRole}`, null);


    const transitionAdminMaybe = isLimitedUser ? getAdminTransitions(transaction): null;
    console.log('transitionMaybe hello', transitionMaybe)
    console.log('transitionAdminMaybe hello', transitionAdminMaybe)
    const confirmTransitions = [
      TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
      TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE,
      TRANSITION_CONFIRM_DROP_OFF,
      TRANSITION_REQUEST_DROP_OFF_DLGO,
    ];
    const needInputOdometer =
      get(currentTransaction, 'attributes.protectedData.payForFuel', false) &&
      [...pickupTransitions, ...dropoffTransitions].includes(nextInteractableTransitions[0]);

    const inputOdometerInvalid =
      !confirmTransitions.includes(nextInteractableTransitions[0]) &&
      needInputOdometer &&
      (isEmpty(this.state.numberOdometer) || isNaN(this.state.numberOdometer));

    const isShowUploadModal =
      (((txIWaitingRequestPickup(currentTransaction) ||
        txIWaitingRequestPickupLTF(currentTransaction)) &&
        isCustomer) ||
        ((txIsBeingPickedUp(currentTransaction) || txIsBeingPickedUpLTF(currentTransaction)) &&
          isProvider) ||
        ((txIWaitingRequestDropoff(currentTransaction) ||
          txIWaitingRequestDropoffLTL(
            currentTransaction && currentTransaction.currentChildTransaction
          )) &&
          isCustomer) ||
        txIsBeingDropOff(currentTransaction) ||
        (txIsBeingDropOffLTL(currentTransaction && currentTransaction.currentChildTransaction) &&
          isProvider)) &&
      haveNextTransit;

    const needDirection = isDrivelahGo ? showExactShuLocation : true;

    let mapSectionPartUnverified = null;
    if (!txInVerificationProcess(transaction) && geolocation) {
      if (isCustomer) {
        if (
          currentCustomer.attributes &&
          currentCustomer.attributes.profile &&
          currentCustomer.attributes.profile.publicData &&
          currentCustomer.attributes.profile.publicData.guestIdentityVerificationStatus ===
            'confirmed'
        ) {
          mapSectionPartUnverified = (
            <AddressLinkMaybe
              rootClassName={css.addressMobile}
              location={isProvider ? customerAddress : this.state.exactAddress}
              geolocation={this.state.exactGeoLocation}
              showAddress={stateData.showAddress}
              isProvider={isProvider}
              needDirection={needDirection}
              showExactShuLocation={showExactShuLocation}
              // customAddress={
              //   isDrivelahGo ? (
              //     <CarAddressData
              //       showExactShuLocation={showExactShuLocation}
              //       address={this.state.exactAddress}
              //       shuStatusLocation={shuStatusLocation}
              //       showFetchLiveLocationButton={showFetchLiveLocationButton}
              //       fetchCurrentAddress={e => {
              //         e.preventDefault();
              //         this.fetchCurrentGeoLocation(transactionId, currentListing);
              //       }}
              //       hrefToGoogleMaps={hrefToGoogleMaps}
              //     />
              //   ) : null
              // }
            />
          );
        }
      } else {
        mapSectionPartUnverified = (
          <AddressLinkMaybe
            rootClassName={css.addressMobile}
            location={isProvider ? customerAddress : location}
            geolocation={this.state.exactGeoLocation}
            showAddress={stateData.showAddress}
            isProvider={isProvider}
            needDirection={needDirection}
            showExactShuLocation={showExactShuLocation}
            // customAddress={
            //   isDrivelahGo ? (
            //     <CarAddressData
            //       showExactShuLocation={showExactShuLocation}
            //       address={this.state.exactAddress}
            //       shuStatusLocation={shuStatusLocation}
            //       fetchCurrentAddress={e => {
            //         e.preventDefault();
            //         this.fetchCurrentGeoLocation(transactionId, currentListing);
            //       }}
            //       hrefToGoogleMaps={hrefToGoogleMaps}
            //     />
            //   ) : null
            // }
          />
        );
      }
    }
    const mapSection = (
      <div className={!txInVerificationProcess(transaction) ? css.mapContainer : null}>
        {isCustomer &&
        !txInVerificationProcess(transaction) &&
        currentCustomer.attributes &&
        currentCustomer.attributes.profile &&
        currentCustomer.attributes.profile.publicData &&
        currentCustomer.attributes.profile.publicData.guestIdentityVerificationStatus ===
          'confirmed' ? (
          <ListingMap
            geolocation={this.state.exactGeoLocation}
            listing={currentListing}
            showAddress={stateData.showAddress}
            address={this.state.exactAddress}
            showExactShuLocation={showExactShuLocation}
          />
        ) : null}
        {isProvider && !txInVerificationProcess(transaction) ? (
          <CustomerMap
            geolocation={this.state.exactGeoLocation}
            transaction={currentTransaction}
            showAddress={stateData.showAddress}
          />
        ) : null}
        {mapSectionPartUnverified}
      </div>
    );

    const chatSection = (
      <div className={isProvider ? css.chatContainerProvider : css.chatContainer}>
        <FeedSection
          onOpenShowImgModal={url => this.setState({ showImgModal: true, showImgModalUrl: url })}
          rootClassName={css.feedContainer}
          currentTransaction={currentTransaction}
          currentUser={currentUser}
          fetchMessagesError={fetchMessagesError}
          fetchMessagesInProgress={fetchMessagesInProgress}
          initialMessageFailed={initialMessageFailed}
          messages={messages}
          oldestMessagePageFetched={oldestMessagePageFetched}
          onOpenReviewModal={this.onOpenReviewModal}
          onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
          totalMessagePages={totalMessagePages}
          isFinishLongTerm={isFinishLongTerm}
        />
        <div id="headMessageForm" className={css.headMessageForm} />
        {showSendMessageForm ? (
          <SendMessageForm
            currentTransaction={currentTransaction}
            currentUser={currentUser}
            messages={messages}
            formId={this.sendMessageFormName}
            rootClassName={css.sendMessageForm}
            messagePlaceholder={sendMessagePlaceholder}
            inProgress={sendMessageInProgress}
            sendMessageError={sendMessageError}
            onFocus={this.onSendMessageFormFocus}
            onBlur={this.onSendMessageFormBlur}
            onSubmit={this.onMessageSubmit}
            submitButtonId={TRIP_SEND_MESSAGE_BUTTON_ID}
          />
        ) : (
          <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
        )}
      </div>
    );

    const photoSectionListCustomer = [
      {
        id: 0,
        title: 'Exterior of the car photos',
        description:
          'Ensure a minimum of 8 photos, one from each side of the car, including front, back, left, right.',
        data: 'exteriorPhotos',
        inboxLabel: 'Remarks (optional)',
        inboxPlaceholder: 'Enter any remarks you have'
      },
      {
        id: 1,
        title: 'Interior of the car photos',
        description: 'If the user sent any interior photos',
        data: 'interiorPhotos',
        inboxLabel: 'Remarks (optional)',
        inboxPlaceholder: 'Enter any remarks you have'
      },
      {
        id: 2,
        title: 'Odometer Photo',
        description: 'Odometer photo as shared by the user along with the reading',
        data: 'odometerPhotos',
        inboxLabel: 'Enter the odometer reading',
        inboxPlaceholder: 'Enter the reading as visible on odometer'
      },
      {
        id: 3,
        title: 'Fuel Gauge Photo',
        description: 'Fuel guage photo along with the reading',
        data: 'fuelGaugePhotos',
        inboxLabel: 'Enter the fuel gauge level (0 to 100)',
        inboxPlaceholder: 'Enter the fuel gauge level (0 to 100)'
      },
    ];

    const photoSectionListProvider = [
      {
        id: 0,
        title: 'Upload photos (optional)',
        description:
          'You can choose to add photos to counter validate and avoid disputes at the end. Not mandatory.',
        data: 'pickupProviderPhoto',
        isEdit: true,
      },
    ];

    let photoSectionListPickUp = isCustomer
      ? [...photoSectionListCustomer]
      : [...photoSectionListProvider];

      if (isLimitedUser) {
        photoSectionListPickUp = photoSectionListPickUp.filter(item => item.title !== "Selfie with ID card");
      }
    const photoSectionListDropOff = isCustomer
      ? [...photoSectionListCustomer]
      : [...photoSectionListProvider];

      photoSectionListDropOff.push({
        id: 4,
        title: 'Fuel Receipt',
        description:
          'If you have topped up the fuel add receipts for that. If no receipts are added we will charge you for fuel on basis of the distance traveled.',
        data: 'fuelReceiptPhotos',
      });
    // }

    const isPickUp =
      txIWaitingRequestPickup(currentTransaction) ||
      txIsBeingPickedUp(currentTransaction) ||
      txIWaitingRequestPickupLTF(currentTransaction) ||
      txIsBeingPickedUpLTF(currentTransaction) ||
      txIsReturnedBackBeforePickUp(currentTransaction);

    const isDropOff =
      txIsBeingDropOff(currentTransaction) ||
      txIWaitingRequestDropoff(currentTransaction) ||
      txIWaitingRequestDropoffLTL(currentTransaction) ||
      txIsBeingDropOffLTL(currentTransaction);

    const isPickUpInterior =
      txIsPickedUp(currentTransaction) ||
      txAfterCancelUpdateTrip(currentTransaction) ||
      (txIsPickedUpLTF(currentTransaction) && isDrivelahGo);

    const onNoteChange = (note, type) => {
      if (this.state[type].length > 0) {
        const photoWithNote = [...this.state[type]];
        photoWithNote.map(i => {
          i.note = note;
        });

        this.setState({ [type]: photoWithNote });
      }
    };

    const isShowPhotoSection = this.state.showPickUpSection || this.state.showDropOffSection;
    console.log('Last Transition', lastTransition);
    const isShowCancelLinkCustomer =
      lastTransition === TRANSITION_CONFIRM_PAYMENT_UNVERIFIED ||
      lastTransition === TRANSITION_CONFIRM_PAYMENT_INSTANT_UNVERIFIED ||
      ((lastTransition === TRANSITION_CONFIRM_PAYMENT || txIsAccepted(transaction)) &&
        isTripYetToStart);
    const isShowAddonsLinkCustomer =
      (lastTransition === TRANSITION_CONFIRM_PAYMENT || txIsAccepted(transaction)) &&
      !isTimeToShowTransitionPickup;

    const isShuEnabledCar =
      currentTransaction.listing &&
      currentTransaction.listing.attributes.metadata &&
      currentTransaction.listing.attributes.metadata.isDrivelahGo;

    const allTransitions = get(currentTransaction, 'attributes.transitions');
    console.log('allTransitions', allTransitions)
    const isPickupRequested =
      allTransitions &&
      allTransitions.find(
        i =>
          i.transition === TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE ||
          i.transition === TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED ||
          i.transition === TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE ||
          i.transition === TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE ||
          i.transition === TRANSITION_TRIP_PICKUP_CONFIRMED_BY_ADMIN_AFTER_ACCEPTED_NON_REFUNDABLE ||
          i.transition === TRANSITION_TRIP_PICK_UP_CONFIRMED_BY_ADMIN_AFTER_ACCEPTED_REFUNDABLE ||
          i.transition === TRANSITION_CONFIRM_PICK_UP_AFTER_ACCEPTED
      );
      const isPickupRequestSend = allTransitions &&
      allTransitions.find(
        i =>
          i.transition === TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE ||
          i.transition === TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED ||
          i.transition === TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE ||
          i.transition === TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE ||
          i.transition === TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE
      );
      const showEndTrip = isPickupRequested && (
        currentTime.diff(moment(isPickupRequested.createdAt), 'minutes') >= 60 ||
        moment(transaction.booking.attributes.displayEnd).diff(currentTime, 'minutes') <= 60
      );

      const isBookingCompleted =
      transaction.booking &&
      moment()
      .tz(timeZone)
      .isSameOrAfter(
        moment(transaction.booking.attributes.displayEnd)
        .add(6, 'hours')
        .tz(timeZone)
      );
      if (showFetchLiveLocationButton) {
        this.setState({
          showViewDetails: true
        })
        this.shouldShowPickUpSection(true)
      }
      console.log(isPickupRequested, lastTransition, isPickupRequestSend)

    let millagePerDay = listing && listing.attributes && listing.attributes.publicData && listing.attributes.publicData.millagePerDay;
    const differenceInHours = (transaction.booking.attributes.displayEnd - transaction.booking.attributes.displayStart) / (1000 * 60 * 60);
    const mileageToShowText = (() => {
      if (transaction?.attributes?.protectedData?.isDistanceCharge) {
        return null;
      }
      if (!differenceInHours || differenceInHours > 12) {
        return `${millagePerDay} kms/day`;
      }
      if (differenceInHours <= 2) {
        return `50 kms/day`;
      } else if (differenceInHours <= 3) {
        return `75 kms/day`;
      } else if (differenceInHours <= 4) {
        return `100 kms/day`;
      } else if (differenceInHours <= 5) {
        return `125 kms/day`;
      } else {
        return `150 kms/day`;
      }
    })();

    const ishowViewDetailButton = isPickUp && showExactShuLocation && !this.state.showViewDetails && !isPickupRequested && !isPickupRequestSend && !(lastTransition === 'transition/request-pick-up-after-accepted')


    const isDistanceCharge = get(transaction, 'attributes.protectedData.isDistanceCharge', false);

    const distanceKmPrice = get(listing, 'attributes.publicData.pricing.distanceKm', '');

    const {isDistanceCharged,
      distanceTravelled,
      distanceCharge: distanceChargeJSON,
      lateReturn: lateReturnJSON,
    } = transaction?.attributes?.metadata;

    const baseConditionForFailedDistanceCharging = !isProvider && isDistanceCharge && distanceKmPrice && (isDistanceCharged === false);

    const excessReductionType = get(transaction, 'attributes.protectedData.isExcessReduction', false) ? 'Premium' : get(transaction, 'attributes.protectedData.isExcessReduction2', false) ? 'Maximum' : '';

    const showDistanceChargingFailedError = baseConditionForFailedDistanceCharging && distanceTravelled; //&& (distanceChargeJSON?.paid !== true);

    const showDistanceFetchingFailedAlert = baseConditionForFailedDistanceCharging && !distanceTravelled;

    const learnMoreForDistance = () => {
      this.setState({isKeyInformationDetialsModalOpen: true});
      const data = {
        headerImage: distance_modal,
        title: 'Distance',
        title2: `Due later @${distanceKmPrice}/km`,
        description: 'You will be charged for distance driven at the end of the trip. Based on your usage the total distance charge will calculated and charged.'
      }
      this.setState({keyDetailsModalContents: data});
    }

    const learnMoreForExcessReduction = () => {
      this.setState({isKeyInformationDetialsModalOpen: true});
      const data = {
        headerImage: protection_modal,
        title: 'Protection',
        title2: excessReductionType,
        description: `You have selected ${excessReductionType} protection.`,
        point1: `1. Your liability is capped at a maximum of ${excessReductionType === 'Premium' ? '$1500' :'$500'}, giving you peace of mind.`,
        point2: `2. This reduces your own and 3rd party damage liability significantly from $3000 to $1500.`
      }
      this.setState({keyDetailsModalContents: data});
    }

    const learnMoreForTolls = () => {
      this.setState({isKeyInformationDetialsModalOpen: true});
      const data = {
        headerImage: toll_modal,
        title: 'Tolls',
        title2: 'Due later',
        description: 'You will be charged for any tolls you encounter during your trip. Toll is usually charged 2-3 weeks after the trip ends, basis the toll reports by the Host.'
      }
      this.setState({keyDetailsModalContents: data});
    }

    return (
      <>
        {showDistanceFetchingFailedAlert && (
          <AlertBox
          title="Distance charge failed"
          message={`Drop-off successfully done, but we failed to charge you for the distance you drove during the trip. Our team will share an invoice for the same soon.`}
          type="error"
          autoCloseTime={5000}
        />
        )}
        { !(this.state.showPickUpSection || this.state.showDropOffSection) || isLimitedUser || ((this.state.showPickUpSection || this.state.showDropOffSection) && isProvider || !this.state.showNewPickUpDropOffSection) ? (
        <div className={classes}>
        <div className={css.container}>
          <div className={css.txInfo}>
            {!isShowPhotoSection && (
              <PanelHeading
                panelHeadingState={stateData.headingState}
                transactionRole={transactionRole}
                providerName={authorDisplayName}
                customerName={customerDisplayName}
                isCustomerBanned={isCustomerBanned}
                listingId={listingId}
                listingTitle={listingTitle}
                listingDeleted={listingDeleted}
              />
            )}

            { this.state.showPickUpSection  &&
              <PickUpSection
              shouldShowPickUpSection={this.shouldShowPickUpSection}
              isCustomer={isCustomer}
              photoSectionList={photoSectionListPickUp}
              setActivePhotoSection={this.setActivePhotoSection}
              activePhotoSection={this.state.activePhotoSection}
              intl={intl}
              onManageDisableScrolling={onManageDisableScrolling}
              isProvider={isProvider}
              isPickUp={isPickUp || isPickUpInterior}
              currentUser={currentUser}
              setTripPhotos={data => {
                this.setState(data);
              }}
              setCleanlinessValue = {data => { this.setState({ cleanlinessValue: data }) }}
              state={this.state}
              allTripPhotos={allTripPhotos}
              inputOdometerInvalid={inputOdometerInvalid}
              onNoteChange={onNoteChange}
              photoSectionListCustomer={photoSectionListCustomer}
              isDrivelahGo={isDrivelahGo}
              isPickedUp={txIsPickedUp(transaction) || txIsPickedUpLTF(transaction)}
              isDropOff={isDropOff}
              isPickupRequested={isPickupRequested}
              interiorPhotoDlGo={interiorPhotoDlGo}
              odometerPhotoDlGo={odometerPhotoDlGo}
              fuelGaugePhotoDlGo={fuelGaugePhotoDlGo}
              shouldshowNewPickUpDropOffSection = {(value) =>  this.setState( {showNewPickUpDropOffSection: value})}
              isLimitedUser={isLimitedUser}
              transitionAdminMaybe={transitionAdminMaybe}
              adminCheckGuest={adminCheckGuest}
              adminCheckGuestLocation={adminCheckGuestLocation}
              onCheckboxChange={this.handleCheckboxChange}
              removePhoto={this.handleRemovePhoto}
              isAdminAction={this.state.adminAction}
             />
             }

            {this.state.showPickUpSection && isLimitedUser && isTabletLayout &&
              <div className={css.mobileInfoContainer}>
                    <BookingInfoSection
                      transaction={transaction}
                      isCustomer={isCustomer}
                      showAvatar={false}
                      listing={currentListing}
                      currentProvider={currentProvider}
                      intl={intl}
                    />

                <div className={css.grayLine} />

                  {isShowPhotoSection ? (
                    <PartnerInfo
                      currentUserRole={currentUserRole}
                      transaction={transaction}
                      otherUser={otherUser}
                      isMobileLayout={isMobileLayout}
                    />
                ) : null}

              </div>
            }

            {showDistanceChargingFailedError && !(this.state.showPickUpSection || this.state.showDropOffSection) && (
              <div>
                <div className={css.distanceChargeContainer}>
                  <div className={css.statusTitle}>Distance charge failed</div>
                  <div className={css.distanceChargeMessage}>
                    <p className={css.distanceChargeText}>
                      We attempted to charge you for the distance you drove during the trip, but the payment
                      failed. Please try again.
                    </p>
                  </div>
                </div>
              </div>
            )}

            {!isShowPhotoSection && !failedDistanceTransitions.includes(lastTransition) && (
              <BookingStatus
                lastBookingUpdate={lastBookingUpdate}
                currentUserRole={currentUserRole}
                transaction={transaction}
                otherUser={otherUser}
                shouldShowPickUpSection={this.shouldShowPickUpSection}
                shouldShowDropOffSection={this.shouldShowDropOffSection}
                transitionMaybe={transitionMaybe}
                transitionAdminMaybe={transitionAdminMaybe}
                isLimitedUser={ isLimitedUser }
                timeZone={timeZone}
                isCustomer={isCustomer}
                isProvider={isProvider}
                providerName={authorDisplayName}
                customerName={customerDisplayName}
                isLongTerm={isLongTerm}
                isDrivelahGo={isDrivelahGo}
                openModalReview={() => this.setState({ isReviewModalOpen: true })}
                isMobileLayout={isMobileLayout}
                openDisputeModal={() => this.setState({ showDisputeModal: true })}
                bookingUpdateTxs={bookingUpdateTxs}
                intl={intl}
                shouldshowNewPickUpDropOffSection = {(value) =>  this.setState( {showNewPickUpDropOffSection: value})}
                openTripExtensionModal={this.onOpenTripExtensionModal}
                activePickUpBooking = {(value) => this.setState({activePickUpBooking: value})}
                activeDropOffBooking = {(value) => this.setState({activeDropOffBooking: value})}
                ishowViewDetailButton={ishowViewDetailButton}
                showDistanceChargingFailedError={showDistanceChargingFailedError}
                isOlderVersion={isOlderVersion}
                setAdminAction={this.setAdminAction}
                isPickUp={isPickUp}
                isDropOff={isDropOff}
              />
            )}

            {isCustomer && txInVerificationProcess(transaction) && (
              <div className={css.chatContainerProvider}>
                <AccountSettingDriverVerificationPage
                  pageName="tripPage"
                  haveNextTransit={haveNextTransit}
                  nextInteractableTransitions={nextInteractableTransitions}
                  isFromTripPage={true}
                  transaction={transaction}
                  transactionId={transactionId}
                  onTransit={onTransit}
                />
              </div>
            )}

            {/* {transactionTitle} */}
            {!isShowPhotoSection && isTabletLayout ? (
              <div className={css.mobileInfoContainer}>
                {!isShowPhotoSection && isTabletLayout ? (
                  <BookingInfoSection
                    transaction={transaction}
                    isCustomer={isCustomer}
                    showAvatar={false}
                    listing={currentListing}
                    currentProvider={currentProvider}
                    intl={intl}
                  />
                ) : null}

                <div className={css.grayLine} />

                {!isShowPhotoSection && isTabletLayout ? (
                  <PartnerInfo
                    currentUserRole={currentUserRole}
                    transaction={transaction}
                    otherUser={otherUser}
                    isMobileLayout={isMobileLayout}
                  />
                ) : null}
              </div>
            ) : null}
            {this.state.showDropOffSection && (
              <DropOffSection
                shouldShowDropOffSection={this.shouldShowDropOffSection}
                isCustomer={isCustomer}
                photoSectionList={photoSectionListDropOff}
                setActivePhotoSection={this.setActivePhotoSection}
                activePhotoSection={this.state.activePhotoSection}
                intl={intl}
                onManageDisableScrolling={onManageDisableScrolling}
                isProvider={isProvider}
                isPickUp={isPickUp}
                isDropOff={isDropOff}
                currentUser={currentUser}
                setTripPhotos={data => {
                  this.setState(data);
                }}
                state={this.state}
                allTripPhotos={allTripPhotos}
                inputOdometerInvalid={inputOdometerInvalid}
                onNoteChange={onNoteChange}
                photoSectionListCustomer={photoSectionListCustomer}
                isLimitedUser={isLimitedUser}
                removePhoto={this.handleRemovePhoto}
                pickUpOdometer={pickUpOdometer}
                isAdminAction={this.state.adminAction}
                dropOffSentAtByAdmin={this.state.dropOffSentAtByAdmin}
                setDropOffSentAt={this.setDropOffSentAt}
                customDropOffReadingByAdmin={value => this.setState({customDropOffReadingByAdmin: value})}
                dropoffSentAt={dropoffSentAt}
                timeZone={timeZone}
              />
            )}

            {!isShowPhotoSection && isProvider && chatSection}
            {
            this.state.exactLocationLoading ?
            <Loader />
            :
            !isShowPhotoSection &&
              lastTransition !== TRANSITION_ENQUIRE &&
              ((isDeliveryTransaction && isProvider) || isCustomer) &&
              mapSection}
            {!isShowPhotoSection &&
              isCustomer &&
              currentCustomer.attributes &&
              currentCustomer.attributes.profile &&
              currentCustomer.attributes.profile.publicData &&
              currentCustomer.attributes.profile.publicData.guestIdentityVerificationStatus ===
                'confirmed' &&
              !txInVerificationProcess(transaction) && (
                  <>
                    <InfoSection
                      listingRegistrationNumber={listingRegistrationNumber}
                      fuelType={fuelType}
                      keyRules={keyRulesConfig}
                      selectedOptions={currentListing.attributes.publicData.keyRules}
                      isMobileLayout={isMobileLayout}
                      showAddress={stateData.showAddress}
                      listing={currentListing}
                      lastTransition={lastTransition}
                      isDrivelahGo={isDrivelahGo}
                      mileageToShow={mileageToShowText}
                      isDistanceCharge={isDistanceCharge}
                      distanceKmPrice={distanceKmPrice}
                      learnMoreForDistance={learnMoreForDistance}
                      excessReductionType={excessReductionType}
                      learnMoreForExcessReduction={learnMoreForExcessReduction}
                      learnMoreForTolls={learnMoreForTolls}
                    />
                  </>
              )}
            {/* Here the deposit section */}
            {showDepositTxSection && !this.state.showDropOffSection && !this.state.showPickUpSection && (
              <div className={css.infoSectionContainer}>
                <div className={css.infoCardContainer}>
                  <div className={css.bodyTextContainer}>
                    <h4 className={css.infoTitle}>{'Deposit'}</h4>
                    <p className={css.infotext}>
                      Your deposit was successfully charged and is on hold. You can request a refund
                      once your trip completes, or keep it on hold for next trip.
                    </p>
                    <a
                      className={css.learnMoreText}
                      onClick={() => history.push('/account/refundable-deposit')}
                    >
                      {' '}
                      <p className={css.infotext}>Learn More</p>{' '}
                    </a>
                  </div>
                </div>
              </div>
            )}

            {!isShowPhotoSection &&
              isCustomer &&
              !(
                txIsUnverifiedRequested(transaction) ||
                txIsUnverifiedInstantRequested(transaction) ||
                txIsUnverifiedSubmittedInstant(transaction) ||
                txIsUnverifiedSubmitted(transaction)
              ) &&
              chatSection}

            {isTabletLayout && !isShowPhotoSection ? (
              <ImportantLinksSection
                history={history}
                isCustomer={isCustomer}
                isAccepted={txIsAccepted(transaction)}
                onOpenCancelModal={() => this.setState({ showCancelModal: true })}
                onOpenCancelHelpModal={() => this.setState({ showCancelHelpModal: true })}
                isShowCancelLinkCustomer={isShowCancelLinkCustomer}
                isShowAddonsLinkCustomer={isShowAddonsLinkCustomer}
                transactionId={transactionId}
                transaction={transaction}
                onEditTrip={this.onEditTrip}
                onPoliciesClick={this.onPoliciesClick}
                isBookingCompleted={isBookingCompleted}
              />
            ) : null}
          </div>
          { isLockboxActive && !isShowPhotoSection && isTabletLayout && (isLimitedUser || (isDropOff && isCustomer)) && (
                 <LockBoxSection listing={listing} isTabletLayout = {isTabletLayout}  findDeviceByType={findDeviceByType} onLockboxDataChange={this.updateLockboxData} isLimitedUser={isLimitedUser}/>
            )
            }

           {isShowPhotoSection && isLimitedUser && (
            <div className={css.asideDesktop}>
              <BookingInfoSection
                transaction={transaction}
                isCustomer={isCustomer}
                showAvatar={false}
                listing={currentListing}
                currentProvider={currentProvider}
                intl={intl}
              />
              <PartnerInfo
                currentUserRole={currentUserRole}
                transaction={transaction}
                otherUser={otherUser}
                show={true}
                isMobileLayout={isMobileLayout}
              />
              { isDrivelahGo || lockbox ? <PickUpByAdmin lockbox={lockbox} transaction={transaction}
                              onLockboxDataChange={this.updateLockboxData}/> : null}
            </div>)
          }


          {!isShowPhotoSection && (
            <div className={css.asideDesktop}>
              <BookingInfoSection
                transaction={transaction}
                isCustomer={isCustomer}
                showAvatar={false}
                listing={currentListing}
                currentProvider={currentProvider}
                intl={intl}
              />
              <PartnerInfo
                currentUserRole={currentUserRole}
                transaction={transaction}
                otherUser={otherUser}
                show={true}
                isMobileLayout={isMobileLayout}
              />
              {isDrivelahGo && !isShowPhotoSection && !isTabletLayout ? (
                <LockUnlockButtonMaybe
                  canShowButtons={haveNextTransit}
                  transaction={currentTransaction}
                  transitInProgress={transitInProgress}
                  transitError={transitError}
                  onTransit={onTransit}
                  currentUser={currentUser}
                  transitionNames={nextInteractableTransitions}
                  handleOpenStatusModal={this.handleOpenStatusModal}
                  tripPhotos={this.state.tripPhotos}
                  allTripPhotos={allTripPhotos}
                  ownListing={ownListing}
                  onPushEventGTM={this.onPushPickUpAndDropOffEventGTM}
                  isTabletLayout={isTabletLayout}
                />
              ) : null}
            {isLockboxActive && !isShowPhotoSection && !isTabletLayout && (isLimitedUser || (isDropOff && isCustomer))  && (
                <LockBoxSection listing={listing} isTabletLayout = {isTabletLayout}  findDeviceByType={findDeviceByType} onLockboxDataChange={this.updateLockboxData} isLimitedUser={isLimitedUser}/>
              )
              }
              <ImportantLinksSection
                history={history}
                isCustomer={isCustomer}
                isAccepted={txIsAccepted(transaction)}
                onOpenCancelHelpModal={() => this.setState({ showCancelHelpModal: true })}
                onOpenCancelModal={() => this.setState({ showCancelModal: true })}
                isShowCancelLinkCustomer={isShowCancelLinkCustomer}
                isShowAddonsLinkCustomer={isShowAddonsLinkCustomer}
                transactionId={transactionId}
                transaction={transaction}
                onEditTrip={this.onEditTrip}
                onPoliciesClick={this.onPoliciesClick}
                isBookingCompleted={isBookingCompleted}
              />
            </div>
          )}
          {updateBookingButtons}
          { ((!isCustomer || isLimitedUser) && (stateData.showSaleButtons || isShowPhotoSection)) || (this.state.showPickUpSection && isLimitedUser && this.state.showPickUpPreviewSection && this.state.showViewDetails) || (!ishowViewDetailButton && !isProvider)  ? (
            <ButtonsSection
              declineInProgress={declineInProgress}
              declineButtonName={declineButtonName}
              isPickupRequested={isPickupRequested}
              transaction={transaction}
              acceptInProgress={acceptInProgress}
              acceptSaleError={acceptSaleError}
              declineSaleError={declineSaleError}
              acceptButtonName={acceptButtonName}
              currentUser={currentUser}
              onDeclineSale={onDeclineSale}
              declineSaleGTM={declineSaleGTM}
              onAcceptSale={onAcceptSale}
              acceptSaleGTM={acceptSaleGTM}
              currentPhotos={this.state.currentPhotos}
              activePhotoSection={this.state.activePhotoSection}
              exteriorPhotos={this.state.exteriorPhotos}
              selfieWithId={this.state.selfieWithId}
              interiorPhotos={this.state.interiorPhotos}
              odometerPhotos={this.state.odometerPhotos}
              fuelGaugePhotos={this.state.fuelGaugePhotos}
              fuelReceiptPhotos={this.state.fuelReceiptPhotos}
              pickupProviderPhoto={this.state.pickupProviderPhoto}
              transitionMaybe={transitionMaybe}
              onTransit={onTransit}
              showSaleButtons={stateData.showSaleButtons}
              showPickUpSection={this.state.showPickUpSection}
              showDropOffSection={this.state.showDropOffSection}
              transitInProgress={transitInProgress}
              inputOdometerInvalid={inputOdometerInvalid}
              isShuEnabledCar={isShuEnabledCar}
              isProvider={isProvider}
              isCustomer={isCustomer}
              allTripPhotos={allTripPhotos}
              isPickUp={isPickUp}
              isDropOff={isDropOff}
              isDrivelahGo={isDrivelahGo}
              isLockboxActive={isLockboxActive}
              listing={listing}
              lockbox={lockbox}
              findDeviceByType={findDeviceByType}
              onPushEventGTMPickUpDropOff={this.onPushPickUpAndDropOffEventGTM}
              ownListing={ownListing}
              onUploadInteriorPhotoToMetadata={onUploadInteriorPhotoToMetadata}
              interiorPhotoDlGo={interiorPhotoDlGo}
              odometerPhotoDlGo={odometerPhotoDlGo}
              fuelGaugePhotoDlGo={fuelGaugePhotoDlGo}
              uploadInteriorPhotoProgress={uploadInteriorPhotoProgress}
              uploadInteriorSuccess={uploadInteriorSuccess}
              shouldShowPickUpSection={this.shouldShowPickUpSection}
              shouldShowDropOffSection={this.shouldShowDropOffSection}
              transitionAdminMaybe={transitionAdminMaybe}
              isLimitedUser={isLimitedUser}
              cleanlinessScore = {this.state.cleanlinessValue}
              setShowViewDetails={this.setShowViewDetails}
              showViewDetails={ishowViewDetailButton}
              shouldShowPickUpPreviewSection = {(value) =>  this.setState( {showPickUpPreviewSection: value})}
              showEndTrip={showEndTrip}
              history={this.props.history}
              isPickUpByAdminButtonEnabled={isPickUpByAdminButtonEnabled}
              lockBoxCode={this.state.lockBoxCode}
              onLockboxDataChange={this.updateLockboxData}
              pickUpOdometer={pickUpOdometer}
              requirePickupLocation={requirePickupLocation}
              requireDropoffLocation={requireDropoffLocation}
              dropOffSentAtByAdmin={this.state.dropOffSentAtByAdmin}
              timeZone={timeZone}
              showDistanceChargingFailedError={showDistanceChargingFailedError}
              customDropOffReadingByAdmin={this.state.customDropOffReadingByAdmin}
            />
          ) : null}
          {isDrivelahGo && isShuEnabledCar && !isShowPhotoSection && isTabletLayout ? (
            <LockUnlockButtonMaybe
              canShowButtons={haveNextTransit}
              transaction={currentTransaction}
              transitInProgress={transitInProgress}
              transitError={transitError}
              onTransit={onTransit}
              currentUser={currentUser}
              transitionNames={nextInteractableTransitions}
              handleOpenStatusModal={this.handleOpenStatusModal}
              tripPhotos={this.state.tripPhotos}
              allTripPhotos={allTripPhotos}
              ownListing={ownListing}
              onPushEventGTM={this.onPushPickUpAndDropOffEventGTM}
              isTabletLayout={isTabletLayout}
            />
          ) : null}
        {(!showDistanceChargingFailedError &&  !showDistanceFetchingFailedAlert) && (<ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
          buttonId={TRIP_PUBLISH_REVIEW_BUTTON_ID}
        />)}
        <TripExtensionModal
          id="TripExtensionModal"
          isOpen={this.state.isTripExtensionModalOpen}
          onCloseModal={() => this.setState({ isTripExtensionModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
        />
        <ModalCancel
          cancelAllUpdateBookingTxsInProgress={cancelAllUpdateBookingTxsInProgress}
          rootClassName={actionButtonClasses}
          canShowButtons={haveNextCancelTransit}
          transaction={transactionForCancel}
          transitInProgress={transitInProgress}
          transitError={transitError}
          onTransit={onTransit}
          transitionNames={nextCancelTransition}
          openCancelPolicyModal={() => this.setState({ isCancelPolicyOpen: true })}
          isCustomer={isCustomer}
          isProvider={isProvider}
          containerClassName={css.modalContainer}
          onManageDisableScrolling={onManageDisableScrolling}
          showCancelModal={this.state.showCancelModal}
          onClose={() => this.setState({ showCancelModal: false })}
          isInstantBooking={isInstantBooking}
          onCancelTripGTM={this.onCancelTripGTM}
          cancelButtonId={CANCEL_BOOKING_BUTTON_ID}
          isAccepted={txIsAccepted(currentTransaction)}
          isLongTerm={isLongTerm}
          currentChildTransaction={currentChildTransaction}
          onFetchCancelUpdateBooking={onFetchCancelUpdateBooking}
          onCancelAllUpdateBookings={onCancelAllUpdateBookings}
        />
        <ModalHelp
          onClose={() => this.setState({ showCancelHelpModal: false })}
          cancelButtonId={CANCEL_BOOKING_BUTTON_ID}
          isOpen={this.state.showCancelHelpModal}
        />
            <ModalCancelPolicy
            containerClassName={css.missingInformationModal}
            onManageDisableScrolling={onManageDisableScrolling}
            transactionRole={transactionRole}
            showPolicyModal={this.state.isCancelPolicyOpen}
            onClose={() => this.setState({ isCancelPolicyOpen: false })}
            isLongTerm={isLongTerm}
            />
            <ModalReasonDispute
            isOpen={this.state.showDisputeModal}
            onClose={() => this.setState({ showDisputeModal: false })}
            onManageDisableScrolling={onManageDisableScrolling}
            transaction={currentTransaction}
            transitInProgress={transitInProgress}
            transitError={transitError}
            onTransit={onTransit}
            onSendMessage={onSendMessage}
            reasonDispute={this.state.reasonDispute}
            reasonDisputePhotoUrls={this.state.reasonDisputePhotoUrls}
            onChoosePhoto={fileUrl => this.handleChooseDisputePhoto(fileUrl)}
            tripPhotos={[
              ...allTripPhotos,
              ...(isShowUploadModal ? this.state.tripPhotos : []),
            ].reduce((acc, val) => acc.concat(val), [])}
            onReasonDisputeChange={value => this.setState({ reasonDispute: value })}
            onPushDisputeEventGTM={this.onPushDisputeEventGTM}
            submitButtonId={TRIP_SUBMIT_DISPUTE_BUTTON_ID}
            />
          </div>
          </div>) :
          this.state.showNewPickUpDropOffSection ?
          (<div>
            <StartTripPanel
              cancelAllUpdateBookingTxsInProgress={cancelAllUpdateBookingTxsInProgress}
              bookingUpdateTxs={bookingUpdateTxs}
              lastBookingUpdate={lastBookingUpdate}
              acceptUpdateBookingInProgress={acceptUpdateBookingInProgress}
              cancelUpdateBookingInProgress={cancelUpdateBookingInProgress}
              className={className}
              currentUser={currentUser}
              transaction={currentTransaction}
              transactionUpdateBooking={transactionUpdateBooking}
              fetchMessagesInProgress={fetchMessagesInProgress}
              totalMessagePages={totalMessagePages}
              oldestMessagePageFetched={oldestMessagePageFetched}
              messages={messages}
              transitionMaybe={transitionMaybe}
              initialMessageFailed={initialMessageFailed}
              savePaymentMethodFailed={savePaymentMethodFailed}
              fetchMessagesError={fetchMessagesError}
              sendMessageInProgress={sendMessageInProgress}
              sendMessageError={sendMessageError}
              sendReviewInProgress={sendReviewInProgress}
              sendReviewError={sendReviewError}
              onManageDisableScrolling={onManageDisableScrolling}
              onShowMoreMessages={onShowMoreMessages}
              onSendMessage={onSendMessage}
              onSendReview={this.props.onSendReview}
              transactionRole={transactionRole}
              onAcceptSale={onAcceptSale}
              onDeclineSale={onDeclineSale}
              acceptInProgress={acceptInProgress}
              declineInProgress={declineInProgress}
              acceptSaleError={acceptSaleError}
              declineSaleError={declineSaleError}
              acceptSaleGTM={acceptSaleGTM}
              declineSaleGTM={declineSaleGTM}
              nextTransitions={nextTransitions}
              onSubmitBookingRequest={this.props.onSubmitBookingRequest}
              timeSlots={timeSlots}
              fetchTimeSlotsError={this.props.fetchTimeSlotsError}
              transitInProgress={transitInProgress}
              transitError={transitError}
              onTransit={onTransit}
              onUploadInteriorPhotoToMetadata={onUploadInteriorPhotoToMetadata}
              ownListing={ownListing}
              transactionWithoutRestore={transactionWithoutRestore}
              acceptButtonName={acceptButtonName}
              declineButtonName={declineButtonName}
              location={location}
              history={history}
              viewport={this.props.viewport}
              callSetInitialValues={this.props.callSetInitialValues}
              onInitializeCardPaymentData={this.props.onInitializeCardPaymentData}
              onResetCode={this.props.onResetCode}
              isMobileLayout={isMobileLayout}
              isTabletLayout={isTabletLayout}
              uploadInteriorPhotoProgress={uploadInteriorPhotoProgress}
              uploadInteriorSuccess={uploadInteriorSuccess}
              monthlyTimeSlots={monthlyTimeSlots}
              onHandlePaymentFuel={onHandlePaymentFuel}
              onEstimateBreakdown={onEstimateBreakdown}
              estimatedTx={estimatedTx}
              estimateError={estimateError}
              estimateBreakdownInProgress={estimateBreakdownInProgress}
              onFetchTimeSlots={onFetchTimeSlots}
              customerUpdateBookingInProgress={customerUpdateBookingInProgress}
              onFetchCancelUpdateBooking={onFetchCancelUpdateBooking}
              listingForMap={listingForMap}
              onAcceptUpdateBooking={onAcceptUpdateBooking}
              onCancelUpdateBooking={onCancelUpdateBooking}
              onCancelAllUpdateBookings={onCancelAllUpdateBookings}
              isLimitedUser={isLimitedUser}
              onCancelUpdateBookingByAdmin={onCancelUpdateBookingByAdmin}
              onAcceptUpdateBookingByAdmin={onAcceptUpdateBookingByAdmin}
              isCancelUpdateBookingByAdminPending={isCancelUpdateBookingByAdminPending}
              isAcceptUpdateBookingByAdminPending={isAcceptUpdateBookingByAdminPending}
              getDistanceFromPickUp={getDistanceFromPickUp}
              distanceFromPickUp={distanceFromPickUp}
              userLocation={userLocation}
              shouldShowPickUpSections={this.shouldShowPickUpSection}
              showNewPickUpSection={this.state.showPickUpSection}
              shouldShowDropOffSections={this.shouldShowDropOffSection}
              showNewDropOffSection={this.state.showDropOffSection}
              isLockboxActive={isLockboxActive}
              listing={listing}
              lockbox={lockbox}
              findDeviceByType={findDeviceByType}
              setShowViewDetails={this.setShowViewDetails}
              mileageToShowText={mileageToShowText}
              exactGeoLocation={this.state.exactGeoLocation}
              exactAddress={this.state.exactAddress}
              isOlderVersion={isOlderVersion}
              requirePickupLocation={requirePickupLocation}
              requireDropoffLocation={requireDropoffLocation}
              isDistanceCharge={isDistanceCharge}
              distanceKmPrice={distanceKmPrice}
              excessReductionType={excessReductionType}
              learnMoreForTolls={learnMoreForTolls}
              learnMoreForExcessReduction={learnMoreForExcessReduction}
              learnMoreForDistance={learnMoreForDistance}
            />
          </div> )
          :
        <div className={classes}>
          <div  className={css.container}>
            <PickUpSection
              shouldShowPickUpSection={this.shouldShowPickUpSection}
              isCustomer={isCustomer}
              photoSectionList={photoSectionListPickUp}
              setActivePhotoSection={this.setActivePhotoSection}
              activePhotoSection={this.state.activePhotoSection}
              intl={intl}
              onManageDisableScrolling={onManageDisableScrolling}
              isProvider={isProvider}
              isPickUp={isPickUp || isPickUpInterior}
              currentUser={currentUser}
              setTripPhotos={data => {
                this.setState(data);
              }}
              state={this.state}
              allTripPhotos={allTripPhotos}
              inputOdometerInvalid={inputOdometerInvalid}
              onNoteChange={onNoteChange}
              photoSectionListCustomer={photoSectionListCustomer}
              isDrivelahGo={isDrivelahGo}
              isPickedUp={txIsPickedUp(transaction) || txIsPickedUpLTF(transaction)}
              isDropOff={isDropOff}
              isPickupRequested={isPickupRequested}
              interiorPhotoDlGo={interiorPhotoDlGo}
              odometerPhotoDlGo={odometerPhotoDlGo}
              fuelGaugePhotoDlGo={fuelGaugePhotoDlGo}
              shouldshowNewPickUpDropOffSection = {(value) =>  this.setState( {showNewPickUpDropOffSection: value})}
              adminCheckGuest={adminCheckGuest}
              adminCheckGuestLocation={adminCheckGuestLocation}
              onCheckboxChange={this.handleCheckboxChange}
              currentPhotos={this.state.currentPhotos}
              isAdminAction={this.state.adminAction}
             />
           </div>
        </div>
        }
        <Modal
        id="keyInformationDetailsModal"
        isOpen={this.state.isKeyInformationDetialsModalOpen}
        onClose={() => {
          this.setState({isKeyInformationDetialsModalOpen: false});
        }}
        containerClassName={css.modalContainer}
        onManageDisableScrolling={false}
        closeButtonMessage={' '}
      >
        <div className={css.learnMoreModalOuterContainer}>
          <div><img src={this.state.keyDetailsModalContents.headerImage} /></div>
          <h2><span>{this.state.keyDetailsModalContents.title}</span><span>{this.state.keyDetailsModalContents.title2}</span></h2>
          <hr />
          <p>What is it?</p>
          <p>{this.state.keyDetailsModalContents.description}</p>
          <p>{this.state.keyDetailsModalContents.point1}</p>
          <p>{this.state.keyDetailsModalContents.point2}</p>
        </div>
      </Modal>
      </>
    );
  }

  stateDataFn(isCustomer, transaction, isProvider, isCustomerBanned, isDelivery) {
    const lastTransition = transaction.attributes.lastTransition;
    if (txIsEnquired(transaction)) {
      return {
        headingState: HEADING_ENQUIRED,
        showBookingPanel: false,
      };
    } else if (txIsPaymentPending(transaction)) {
      return {
        headingState: HEADING_PAYMENT_PENDING,
        showDetailCardHeadings: isCustomer,
      };
    } else if (txIsPaymentExpired(transaction)) {
      return {
        headingState: HEADING_PAYMENT_EXPIRED,
        showDetailCardHeadings: isCustomer,
      };
    } else if (txIsWithdrawnByAdmin(transaction)) {
      return {
        headingState: HEADING_WITHDRAWN_BY_ADMIN,
        showDetailCardHeadings: true,
      };
    } else if (txIsWithdrawn(transaction)) {
      return {
        headingState: HEADING_WITHDRAWN,
        showDetailCardHeadings: isCustomer,
      };
    } else if (txIsRequested(transaction) || txIsRequestedLTF(transaction)) {
      return {
        headingState: HEADING_REQUESTED,
        showDetailCardHeadings: isCustomer,
        showSaleButtons: isProvider && !isCustomerBanned,
      };
    } else if (
      txIsAccepted(transaction) &&
      (
        lastTransition === TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP ||
        lastTransition === TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE ||
        lastTransition === TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF)
    ) {
      return {
        headingState: HEADING_DECLINED_UPDATE_BOOKING,
        showDetailCardHeadings: true,
        showAddress: isDelivery ? false : isCustomer,
      };
    } else if (
      txIsAccepted(transaction) &&
      (lastTransition === TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP ||
        lastTransition === TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP)
    ) {
      return {
        headingState: HEADING_ACCEPTED_UPDATE_BOOKING,
        showDetailCardHeadings: true,
        showAddress: isDelivery ? false : isCustomer,
      };
    } else if (txIsAccepted(transaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsAcceptedUpdateBookingBeforePickUp(transaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsAcceptedUpdateBookingBeforeDropOff(transaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsAutoAcceptedUpdateBookingBeforeDropOff(transaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsCancelledUpdateBookingBeforeDropOff(transaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsAutoAcceptedUpdateBookingBeforePickUp(transaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsCancelledUpdateBookingBeforePickUp(transaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsAwaitUpdateBookingBeforePickUp(transaction)) {
      return {
        headingState: HEADING_REQUEST_UPDATE_BOOKING,
        showDetailCardHeadings: isCustomer,
        showAddress: isDelivery ? false : isCustomer,
        showBookingButtons: isProvider,
      };
    } else if (txIsAwaitUpdateBookingBeforeDropOff(transaction)) {
      return {
        headingState: HEADING_REQUEST_UPDATE_BOOKING,
        showDetailCardHeadings: isCustomer,
        showAddress: isDelivery ? false : isCustomer,
        showBookingButtons: isProvider,
      };
    } else if (
      txIWaitingRequestPickup(transaction) ||
      txIWaitingRequestPickupLTF(transaction) ||
      txIsBeingDropOff(transaction) ||
      txIsBeingDropOffLTL(transaction)
    ) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsBeingPickedUp(transaction) || txIsBeingPickedUpLTF(transaction)) {
      return {
        headingState: HEADING_REQUESTED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIWaitingRequestDropoff(transaction) || txIWaitingRequestDropoffLTL(transaction)) {
      return {
        headingState: HEADING_ONGOING,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsShu(transaction) || txIsPickedUp(transaction) || txIsPickedUpLTF(transaction)) {
      return {
        headingState: HEADING_ONGOING,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsDeclined(transaction) || txIsDeclinedLTF(transaction)) {
      return {
        headingState: HEADING_DECLINED,
        showDetailCardHeadings: isCustomer,
      };
    } else if (txIsCanceled(transaction)) {
      return {
        headingState: HEADING_CANCELED,
        showDetailCardHeadings: isCustomer,
      };
    } else if (txHasBeenDelivered(transaction)) {
      return {
        headingState: HEADING_DELIVERED,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsUnverifiedRequested(transaction)) {
      return {
        headingState: HEADING_PAYMENT_PENDING,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsUnverifiedInstantRequested(transaction)) {
      return {
        headingState: HEADING_PAYMENT_PENDING,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else if (txIsUnverifiedSubmitted(transaction)) {
      return {
        headingState: HEADING_PAYMENT_PENDING,
        showDetailCardHeadings: isCustomer,
        showAddress: isCustomer || isProvider,
      };
    } else {
      return { headingState: 'unknown' };
    }
  }
}

TripPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  transitError: null,
  keyRulesConfig: config.custom.keyRules,
  nextTransitions: null,
};

TripPanelComponent.propTypes = {
  cancelAllUpdateBookingTxsInProgress: bool,
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  nextTransitions: array,

  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,

  //transit props
  transitInProgress: bool.isRequired,
  transitError: propTypes.error,
  onTransit: func.isRequired,

  // from injectIntl
  intl: intlShape,

  keyRulesConfig: PropTypes.array,
};

const TripPanel = injectIntl(TripPanelComponent);

export default TripPanel;
